/*=========================================================================================
  File Name: moduleActivityState.js
  Description: Activity Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    Hospitals: [],
    languages: [],
    specialities: [],
    HospitalSurgeries: [],
    PatientReservationSurgeries: [],
    HospitalDoctors: [],
    specialities: [],
    HospitalSpecialities: [],
    HospitalSearchObj: {
        CountryID: '',
        HospitalName: null,
        HospitalGroupID: null,
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20
    }

}
