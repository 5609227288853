/*=========================================================================================
  File Name: moduleDoctorActions.js
  Description: Doctor Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";


export default {
    AddHospital({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Hospital/AddHospital", item)
                .then(response => {
                    debugger
                     commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    // AddPatient({ commit }, item) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post("/api/Patient/AddPatient", item)
    //             .then(response => {
    //                 commit(
    //                     "ADD_ITEM",
    //                     Object.assign(item, { ID: response.data.Data.ID })
    //                 );
    //                 resolve(response);
    //             })
    //             .catch(error => {
    //                 reject(error);
    //             });
    //     });
    // },
    GetAllHospitals({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/Hospital/SearchHospitals", search)
                .then(response => {
                    commit("SET_Hospital", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetHospitalParentSpecialty(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialty/GetHospitalParentSpecialty?HospitalID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetSpecialtyByParentSpecialtyID({ commit }, specialtyID) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              "api/Specialty/GetSpecialtyByParentSpecialtyID?ParentSpecialtyID=" +
                specialtyID
            )
            .then(response => {
              commit("SET_ChildSpecialty", response.data.Data);
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        });
      },
      AddHospitalSpecialty(context, data) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/HospitalSpecialty/AddHospitalSpecialty",data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    AddHospitalSpecialtyDoctor(context, data) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/HospitalSpecialtyDoctor/AddHospitalSpecialtyDoctor",data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SearchHospitalsPaging({ commit }, Model) {
        debugger
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Hospital/SearchHospitals", Model)
                .then(response => {
                    debugger
                    var obj = {};
                    obj.Hospitals = response.data.Data;
                    obj.PageNumber = Model.PageNumber;
                    debugger;
                    commit("SET_SearchHospitalsPaging", obj);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdateHospital(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/Hospital/UpdateHospital", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdateHospitalSpecialtyDoctor(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/HospitalSpecialtyDoctor/UpdateHospitalSpecialtyDoctor", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    UpdateHospitalSpecialty(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/HospitalSpecialty/UpdateHospitalSpecialty", item)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetHospitalSurgerysByHospitalID(context, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(
                    "/API/HospitalSurgery/GetHospitalSurgerysByHospitalID?hospitalID="+id
                )
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetHospitalSurgeries({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/HospitalSurgery/SearchHospitalSurgerys",
                    item
                )
                .then(response => {
                    commit("SET_Hospitalurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeleteAttachmenByID(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/Hospital/DeleteAttachmenByID?HospitalattachmentID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UploadImage(context, image) {
      const formData = new FormData();
      // var jsonObject = image.params.Id;
      formData.append("file",image, image.name);
      return new Promise((resolve, reject) => {
        axios
          .post("/API/Common/UploadFile", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            if (response.status == 200) {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    getPatientReservationSurgeries({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/PatientReservationSurgery/SearchPatientReservationSurgerys",
                    item
                )
                .then(response => {
                    commit("SET_PatientReservationSurgeries", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    GetAllSpecialties({ commit }, item) {
        var list = [];
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSpecialty/SearchHospitalSpecialtys", item)
                .then((response) => {
                    commit('SET_HospitalSpecialities', response.data.Data)

                    response.data.Data.forEach((specialty) => {
                        list.push(specialty.Specialty);

                    });
                    commit('SET_specialities', list)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetAllHospitalSpecialties({ commit }, item) {
        var list = [];
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSpecialty/GetAllHospitalSpecialties", item)
                .then((response) => {
                    commit('SET_specialities', response.data.Data)

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },



    GetHospitalSurgeryDoctors({ commit }, item) {
        var list = [];
        return new Promise((resolve, reject) => {
            axios.get("api/HospitalSurgeryDoctor/GetHospitalSurgeryDoctors?HospitalSurgeryID="+item.HospitalSurgeryID)
                .then((response) => {


                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },


    GetHospitalDoctors({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    "/API/HospitalSpecialtyDoctor/SearchHospitalSpecialtyDoctors",
                    item
                )
                .then(response => {
                    debugger
                    commit("SET_HospitalDoctors", response.data.Data);
                    resolve(response);
                    debugger
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    ConfirmUser(context, userId) {
        return new Promise((resolve, reject) => {
            axios
                .post("/ConfirmUser?userId=" + userId)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetHospital(context, itemid) {
        return new Promise((resolve, reject) => {
            axios
                .get("api/Hospital/GetHospitalByID?HospitalID=" + itemid)
                .then(response => {
                    // commit('UPDATE_Doctor', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    removeHospitalRoom(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Hospital/DeleteHospitalRoomByID?hospitalRoomID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeleteHospitalSpecialtyDoctor(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .delete("/API/HospitalSpecialtyDoctor/DeleteHospitalSpecialtyDoctor?ID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeletePatientReservationSurgery(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/PatientReservationSurgery/DeletePatientReservationSurgery?reservationSurgeryID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeleteHospitalSpecialty(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .delete("/API/HospitalSpecialty/DeleteHospitalSpecialty?ID=" + item.ID)
                .then(response => {
                    // commit('REMOVE_ITEM', item.ID)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeleteHospital({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .delete("api/Hospital/DeleteHospital?HospitalID=" + item.ID)
                .then(response => {
                    commit("REMOVE_ITEM", item.ID);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


};
