<template>
  <div class="vx-row ml-5">
       <vs-button
              class="mb-5 ml-5 lg:w-1/8"            
              :color="mainTabName =='profile' ? 'primary' : '#0084FF'"
               :to="{
                  name: 'PatientEdit',
                  params: { ID: id },
                }"
              >{{ $t("Profile") }}</vs-button
            >

             <vs-button
              class="mb-5 ml-5 lg:w-1/8"
               :color="mainTabName=='MedicalHistory' ? 'primary' : '#0084FF'"
               :to="{
                  name: 'PatientMedicalHistory',
                  params: { ID: id },
                }"
              >{{ $t("Medical History") }}</vs-button
            >

             <vs-button
              class="mb-5 ml-5 lg:w-1/8"
              :color="mainTabName=='Reports' ? 'primary' : '#0084FF'"
              :to="{
                  name: 'PatientMedicalHistoryLists',
                  params: { ID: id },
                }"
              >{{ $t("Reports") }}</vs-button>
              
                  <vs-button
              class="mb-5 ml-5 lg:w-1/8"
              :color="mainTabName=='Appointments' ? 'primary' : '#0084FF'"
              :to="{
                  name: 'ReservationSession',
                  params: { ID: 0,PatientID: id,IsAppointment: true },
                }"
              >{{ $t("Appointments") }}</vs-button>

                     <vs-button
              class="mb-5 ml-5 lg:w-1/8"
              :color="mainTabName=='PendingPayments' ? 'primary' : '#0084FF'"
              :to="{
                  name: 'PendingPayment',
                  params: { ID: id },
                }"
              >{{ $t("Pending Payments") }}</vs-button>

                <vs-button
              class="mb-5 ml-5 lg:w-1/8"
              :color="mainTabName=='Surgeries&Treatments' ? 'primary' : '#0084FF'"
              :to="{
                  name: 'PatientReservationSurgery',
                  params: { ID: id },
                }"
              >{{ $t("Surgeries&Treatments") }}</vs-button>
              
            <vs-button
              class="mb-5 ml-5 lg:w-1/8"
              :color="mainTabName=='PendingMedicalDocumentation' ? 'primary' : '#0084FF'"
              :to="{
                  name: 'PendingMedicalDocumentation',
                  params: { ID: id },
                }"
              >{{ $t("Pending Docs ") }}</vs-button>


    </div>
</template>
<script>
export default {
  data() {
    return {
      id:0,
    };
  },
   props: {
    mainTabName: {
      type: String,
      required: true
    }
  },
  methods: {
   
  },

created(){
   if(this.$route.params.ID>0){
     this.id=  this.$route.params.ID

   }
   else{
     this.id=  this.$route.params.PatientID
   }
}
};
</script>
<style>

</style>
