export default {
    ADD_ITEM(state, item) {
        state.Hospitals.unshift(item)
    },
    SET_Hospital(state, Hospital) {
        state.Hospitals = Hospital
    },
    SET_languages(state, languages) {
        state.languages = languages
    },
    SET_specialities(state, specialities) {
        state.specialities = specialities
    },
    UPDATE_Hospital(state, Hospital) {
        const HospitalIndex = state.Hospitals.findIndex((p) => p.ID == Hospital.ID)
        Object.assign(state.Hospitals[HospitalIndex], Hospital)
    },
    SET_SearchHospitalsPaging(state, obj) {
        debugger
        if (obj.PageNumber == 1) {
            state.Hospitals = obj.Hospitals
        } else {
            if (obj.Hospitals.length > 0) {
                obj.Hospitals.forEach(element => {
                    state.Hospitals.push(element)
                });
            }
        }
    },
    REMOVE_ITEM(state, itemId) {
        const ItemIndex = state.Hospitals.findIndex((p) => p.ID == itemId)
        state.Hospitals.splice(ItemIndex, 1)
    },
    SET_Hospitalurgeries(state, HospitalSurgeries) {
        state.HospitalSurgeries = HospitalSurgeries
    },
    SET_PatientReservationSurgeries(state, PatientReservationSurgeries) {
        state.PatientReservationSurgeries = PatientReservationSurgeries
    },
    SET_HospitalDoctors(state, HospitalDoctors) {
        state.HospitalDoctors = HospitalDoctors
    },
    SET_HospitalSpecialities(state, HospitalSpecialities) {
        state.HospitalSpecialities = HospitalSpecialities
    },
    SET_HospitalSearchObj(state, HospitalSearchObj) {
        debugger
        state.HospitalSearchObj = HospitalSearchObj;
    }
}