<template>
  <div id="data-list-list-view">
                <feather-icon icon="ArrowLeftIcon" svgClasses="h-8" style="cursor:pointer" @click="$router.push('/Patient')"/>
    <patient-Tab
                  class="mt-4"
                  :mainTabName="'Surgeries&Treatments'"
                />
    <div>
      <vx-card class="flex-1" v-bind:title="$t('Patient Reservation Surgeries')">
        <vs-table
          ref="table"
          pagination
          :max-items="10"
          :data="PatientReservationSurgeries"
        >
          <template slot="thead">
            <vs-th> {{ $t("Hospital") }}</vs-th>
        <vs-th sort-key="Doctor">{{ $t("Reference Number") }}</vs-th>

            <!-- <vs-th> {{ $t("Doctor Comment") }}</vs-th>
            <vs-th> {{ $t("Symptom Note") }}</vs-th>
            <vs-th> {{ $t("Requested Report Note") }}</vs-th>

            <vs-th> {{ $t("Diagnosis Note") }}</vs-th> -->

            <vs-th> {{ $t("Surgery") }}</vs-th>
            <vs-th> {{ $t("Status") }}</vs-th>
            <vs-th> {{ $t("Payment Status") }}</vs-th>

            <vs-th> {{ $t("Surgery Date From") }}</vs-th>
            <vs-th> {{ $t("Surgery Date To") }}</vs-th>
              <vs-th> {{ $t(" Surgery Date") }}</vs-th>
            <vs-th> {{ $t(" Doctor") }}</vs-th>
            <vs-th> {{ $t("Action") }}</vs-th>

          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p
                    v-if="tr.HospitalSurgery"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.HospitalSurgery.Hospital.Name }}
                  </p>
                </vs-td>
                 <vs-td>
              <p
                class="product-name font-medium truncate"
              >
                {{ tr.ReferenceNumber }}
              </p>
            </vs-td>
                <!-- <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.DoctorComment }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.SymptomNote }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.RequestedReportNote }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.DiagnosisNote }}
                  </p>
                </vs-td> -->
                <vs-td>
                  <p v-if="tr.HospitalSurgery.Surgery" class="product-name font-medium truncate">
                    {{ tr.HospitalSurgery.Surgery.Name }}
                  </p>
                </vs-td>
                 <vs-td>
                  <p v-if="tr.Status" class="product-name font-medium truncate">
                    {{ tr.Status.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.PaymentStatus"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.PaymentStatus.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p
                    v-if="tr.SurgeryDateFrom"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.SurgeryDateFrom | formatDate  }}
                  </p>
                </vs-td>
                  <vs-td>
                  <p
                    v-if="tr.SurgeryDateTo"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.SurgeryDateTo | formatDate  }}
                  </p>
                </vs-td>
                   <vs-td>
                  <p
                    v-if="tr.SurgeryDate"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.SurgeryDate }}
                  </p>
                </vs-td>
                     <vs-td>
                  <p
                    v-if="tr.Doctor"
                    class="product-name font-medium truncate"
                  >
                    {{ tr.Doctor.NameEN }}
                  </p>
                </vs-td>

                    <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editData(tr)"
              />
              <!-- <feather-icon
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click="openConfirm(tr)"
              /> -->
            </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
     <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">

            <vs-button type="border" color="danger" :to="{ name: 'Patient' }">{{
              $t("back")
            }}</vs-button>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import patientTab from "@/views/tabs/patientTab.vue";
export default {
  components: {
    VuePerfectScrollbar,
    Datepicker,
    patientTab

    // vSelect,
  },

  computed: {
    PatientReservationSurgeries() {
      return this.$store.state.patientList.PatientReservationSurgeries;
    }
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        years: []
      },
      patientModel: {},
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},
      experienceModel: {},
      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile"
    };
  },
  methods: {
     editData(item){
    this.$router.push({
        name: "ReservationSurgery-Details",
        params: {ID: item.ID }
      });
    },
     openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      this.$store.dispatch("HospitalList/DeletePatientReservationSurgery", this.rowDataForDelete).then(()=>{
        this.getPatientReservationSurgery(this.$route.params.ID);
         this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully")
      });
        this.rowDataForDelete={};
      }).catch(err => {
        console.error(err);
      });

    },
    getPatientReservationSurgery(id) {
      var model = {};
      model.PatientId = id;
      model.stausID = 1; //pending
      this.$vs.loading();

      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    // GetSurgery() {
    //   this.$vs.loading();

    //   this.$store
    //     .dispatch("patientList/GetSurgery", 3)
    //     .then(() => {
    //       this.$vs.loading.close();
    //     })
    //     .catch(() => {
    //       this.$vs.loading.close();
    //     });
    // }
  },
  created() {
    if (!moduleHospital.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // this.GetSurgery();
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.$store.dispatch("patientList/GetPatient", ID).then(res => {
        this.patientModel = res.data.Data;
        this.$store.commit("SET_SetTitle",''+ this.patientModel.NameEN);
      });
      this.getPatientReservationSurgery(ID);
    }
  }
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
