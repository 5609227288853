var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vx-row ml-5"},[_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName =='profile' ? 'primary' : '#0084FF',"to":{
                name: 'PatientEdit',
                params: { ID: _vm.id },
              }}},[_vm._v(_vm._s(_vm.$t("Profile")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName=='MedicalHistory' ? 'primary' : '#0084FF',"to":{
                name: 'PatientMedicalHistory',
                params: { ID: _vm.id },
              }}},[_vm._v(_vm._s(_vm.$t("Medical History")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName=='Reports' ? 'primary' : '#0084FF',"to":{
                name: 'PatientMedicalHistoryLists',
                params: { ID: _vm.id },
              }}},[_vm._v(_vm._s(_vm.$t("Reports")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName=='Appointments' ? 'primary' : '#0084FF',"to":{
                name: 'ReservationSession',
                params: { ID: 0,PatientID: _vm.id,IsAppointment: true },
              }}},[_vm._v(_vm._s(_vm.$t("Appointments")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName=='PendingPayments' ? 'primary' : '#0084FF',"to":{
                name: 'PendingPayment',
                params: { ID: _vm.id },
              }}},[_vm._v(_vm._s(_vm.$t("Pending Payments")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName=='Surgeries&Treatments' ? 'primary' : '#0084FF',"to":{
                name: 'PatientReservationSurgery',
                params: { ID: _vm.id },
              }}},[_vm._v(_vm._s(_vm.$t("Surgeries&Treatments")))]),_c('vs-button',{staticClass:"mb-5 ml-5 lg:w-1/8",attrs:{"color":_vm.mainTabName=='PendingMedicalDocumentation' ? 'primary' : '#0084FF',"to":{
                name: 'PendingMedicalDocumentation',
                params: { ID: _vm.id },
              }}},[_vm._v(_vm._s(_vm.$t("Pending Docs ")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }